import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useAuth } from '../../contexts/auth'
import { useRegion } from '@/contexts/region-context'
import { getRegionByLocation } from '../../api-client/profile/get-region-by-location'
import useLocation from '../../hooks/use-location'
import CoveoScripts from '@/utils/coveo-scripts'
import SetDyContext from '@/utils/set-dy-context'

const Home = ({}) => {
  const { t } = useTranslation(['common'])
  const { locale } = useRouter()
  const { isAuthenticated } = useAuth()
  const { selectedRegionId, updateRegion, openRegionModal } = useRegion()
  const [location, locationIsResolving] = useLocation(setUserDeniedLocation)

  const [userDeniedLocation, setUserDeniedLocation] = useState(false)
  const [allElementsLoaded, setAllElementsLoaded] = useState(false)

  const sections = Array.from({ length: 50 }, (_, i) => i + 1)

  const { mutate: mutateLocation } = useMutation(getRegionByLocation, {
    onSuccess: (res) => {
      updateRegion(res)
    },
  })

  const checkForCoveoElements = async (intervalId) => {
    const searchBox = document.querySelector('custom-search-box')
    const atomicSearchInterface = document.querySelector('atomic-search-interface')
    if (searchBox && atomicSearchInterface) {
      setAllElementsLoaded(true)
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    if (!allElementsLoaded) {
      let intervalId

      intervalId = setInterval(() => {
        checkForCoveoElements(intervalId)
      }, 100)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [allElementsLoaded])

  useEffect(() => {
    if (!!!selectedRegionId && !isAuthenticated) {
      if (!!locationIsResolving) {
        console.log('userLocation is still resolving')
        return
      }
      if (!!userDeniedLocation) {
        openRegionModal()
        return
      }
      if (!location) {
        console.log('location is still not ready')
        return
      }
      if (location.lat && location.lng) {
        mutateLocation({ latitude: location.lat, longitude: location.lng })
      } else {
        openRegionModal()
        return
      }
    } else {
      if (selectedRegionId > 0 && !isAuthenticated) {
        updateRegion(selectedRegionId)
        const hash = window.location.hash
        if (hash.indexOf('#SelectDivision?url=') !== -1) {
          // Replace the part of the hash that contains the SelectDivision and ReturnURL
          const newHash = hash.replace('#SelectDivision?url=', '')
          // Redirect to the new URL without the #SelectDivision?ReturnURL=
          window.location.href = newHash.indexOf('we_customers_that_ordered') !== -1 ? `${window.location.origin}/${locale}/${t('common.searchpage')}` : newHash
          return
        }
      }
    }
  }, [location, locationIsResolving, selectedRegionId])

  return (
    <>
      {sections.map((sectionId) => (
        <div key={sectionId} id={`dy-section${sectionId}`}></div>
      ))}
      {allElementsLoaded && <CoveoScripts />}
      <SetDyContext type='HOMEPAGE' />
    </>
  )
}

export default dynamic(() => Promise.resolve(Home), {
  ssr: false,
})
